import { Workbox } from 'workbox-window'

let wb

if ('serviceWorker' in navigator) {
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`)

  wb.addEventListener('controlling', () => {
    //window.location.reload()
  })

  wb.addEventListener('waiting', (event) => {
    wb.messageSkipWaiting();
    console.log(`A new service worker has installed, but it can't activate` +
        `until all tabs running the current version have fully unloaded.`);
  });

  wb.register();
} else {
  wb = null
}

export default wb
